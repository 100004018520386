import { Injectable } from '@angular/core';
import { map, take, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SrvService } from '../srv.service';

@Injectable({
    providedIn: 'root'
})
export class DctService{
    currentOverride:{}

    constructor(
        private srv: SrvService,
    ){}

    getEntity$( dctkey:string, code:any ): Observable<any> {
        let id = code instanceof Object ? code.id : code;
        return this.srv
            .dct$( dctkey )
            .pipe(
                filter(dct =>!!dct),
                take(1),
                // tap( dct => console.log('DCT fetched', dctkey, id, dct, dct[id])),
                map( dct => dct[id] )
            )
    }

    getName$( dctkey:string, code:string ): Observable<string> {
        return this.getEntity$(dctkey, code).pipe(
            map( ent =>{
                // console.log(' ->', ent);
                if( ent && ent.name ) return ent.name;
                else return `[?].${dctkey}.${code}`;
            })
        )
    }

}
