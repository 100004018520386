import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'leaflet';
import 'leaflet-contextmenu';
import 'leaflet.markercluster';
import '@notecode/leaflet-grid';

if( environment.production ){
    enableProdMode();
    if( window ){
        window.console.log = () => {};
    }
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));
