import { Pipe, PipeTransform } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { DctService } from './dct.service';

@Pipe({
    name: 'dct'
})
export class DctPipe implements PipeTransform {
    constructor(
        private dct: DctService
    ){}

    async transform(code: string, dctkey: string){
        let value = await firstValueFrom( this.dct.getName$(dctkey, code));
        // console.log('[DEV][DCT-PIPE]', {value});
        return value;
    }
}
