export const fileDownloaderFn = ( fileUrl: string, fileName: string, mimeType?: string )=>{
    // console.log('[DEBUG]', fileUrl, fileName)
    const element = document.createElement('a');
    element.setAttribute('href', fileUrl);
    if( mimeType && ( mimeType === 'application/pdf' || mimeType === 'application/zip' ) )
        element.setAttribute('download', fileName);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

export const copyMessage = ( val: string )=>{
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    window.navigator.clipboard.writeText( val )
    document.body.removeChild(selBox);
}
