import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of, Subject, Subscription, throwError, timer } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { LoadingController, ToastController } from '@ionic/angular';
import { catchError, filter, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
    ContentToSign, EntData, EntSchema, ItemToTransit, LanguageCases, PostTrackingData, SrvResponse
} from './srv.types';
import { fileDownloaderFn } from './app.utils';
import { IActTask } from './ws/analytics/analytics.types';
import { ToastService } from './toast.service';
import { ActivatedRoute, Router } from '@angular/router';

const MAX_PAGE_SIZE = 9999;

const HEADERS = new HttpHeaders()
    .set( 'Content-Type', 'application/vnd.api+json' )
    .set( 'Accept', 'application/vnd.api+json' )

const ACTION_HEADERS = new HttpHeaders()
    .set( 'Content-Type', 'application/json' )

const JSONHEADERS = new HttpHeaders()
    .set( 'Content-Type', 'application/json' )
    .set( 'Accept', 'application/vnd.api+json' )

let counter = 0;

let __cash = {};
let __cash$: { [key: string]: Subject<any> } = {};

[
    'dangerous_class',
    'wastetank_method',
    'wastetank_type',
    'wasteplace_norm_doc_type',
    'statused_statuses',
    'statused_transits',
    'statused_transit_groups',
    'facility_planed_work',
    'facility_operation_types',
    'facility_operation_mobility_types',
    'facility_waste_units',
    'facility_onvos_categories',
    'vehicle_types',
    'justifications',
    'wastereport_operation_types',
    'license_waste_types',
    'license_statuses',
    'accrual_status',
    'state_offer',
    'container_type',
    'facility_onvos_categories',
    'organization_info_vat_cond',
    'waste_container_type_dangerous_classes',
    'trip_part_type',
    'trip_report_type',
    'trip_report_state',
    'trip_facility_report_state',
    'transport_alternative',
    'task_state',
    'region',
    'class_fkko',
    'claim_payer_waste_source',
    'claim_payer_facility',
    'claim_payer_transporter',
    'contract_operation_types',
    'waste_conflict_types',
    'recyclereports_waste_types',
    'contract_waste_generator_years',
    'filter_preset',
    'payment_state'
].forEach( key => {
    __cash$[`dct.${key}`] = new BehaviorSubject( null );
    __cash$[`all.${key}`] = new BehaviorSubject([]);
})

// @ts-ignore
if ( window ) window.$srv = { cash: __cash, _$: __cash$ };

@Injectable({
    providedIn: 'root'
})
export class SrvService {
    public toastPresented$: BehaviorSubject<boolean> = new BehaviorSubject( false );
    public getTerm: ( code: string ) => string;
    public ids: { user_profile: string | null, organization_info: number | string } = { user_profile: null, organization_info: null };
    private errorTargetMap = {
        user: 'user_profile',
        org_owner: 'organization_info'
    }
    public profile;
    public organizationInfo$: BehaviorSubject<any> = new BehaviorSubject( null );
    private triggerSbscrptn: Subscription;

    constructor(
        private http: HttpClient,
        public loading: LoadingController,
        private toast: ToastController,
        private toastService: ToastService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        // @ts-ignore
        if ( window ) window.srv = this;
        this.organizationInfo$.pipe( filter<{id: string}>( Boolean )).subscribe(( orgInfo ) => {
            this.ids.organization_info = orgInfo.id;
        })
    }

    public openProfile() {
        this.gotoEntity( 'user_profile', this.ids.user_profile );
    }

    dct$( dctkey ) {
        if ( !__cash$[`dct.${dctkey}`]) console.warn( `Словарь не определен: ${dctkey}` );
        return __cash$[`dct.${dctkey}`];
    }

    getEntSnapshot( ent ) {
        if ( ent && ent.$isVnd ) {
            if ( !ent.$snapshot && ent.$makeup ) ent.$makeup();
            return ent.$snapshot;
        } else
            return ent;
    }

    fetchEntSigningData$( entity: ItemToTransit | EntData, o?: { captionErr?: string }): Observable<ContentToSign> {
        return this.http.get<SrvResponse>(
            `/signatureapi/v1/${entity.type}/${entity.id}/`,
            {
                headers: HEADERS,
                // @ts-ignore
                responseType: 'text',
            }
        ).pipe(
            map( response => {
                console.log( '[SRV][DATA_TO_SIGN]', response );
                let parsedResponse;
                let str;
                try {
                    parsedResponse = JSON.parse( response.toString());
                    str = parsedResponse.data.attributes.__str__;
                } catch ( e ) {
                    console.error( '[SRV][ERROR]', e );
                    parsedResponse = {};
                }
                return {
                    name: str,
                    object: parsedResponse,
                    content: response.toString(),
                } as ContentToSign
            }),
            tap(( contentToSign: ContentToSign ) => {
                entity.$contentToSign = contentToSign;
                console.log( '  ->', contentToSign );
            }),
            catchError( e => {
                let message = o.captionErr;
                if ( e.error?.errors instanceof Array ) {
                    message = e.error.errors.reduce(( acc, v ) =>
                        `${acc ? acc + '; ' : ''
                        }${v.status ? v.status + ': ' : ''
                        }${v.detail || v.code || ''
                        }`
                        , '' );
                } else if ( e.error?.errors?.error ) {
                    message = typeof e.error.errors.error === 'string'
                        ? e.error.errors.error
                        : JSON.stringify( e.error.errors.error );
                }
                return from(
                    this.toastService.showToast( 'Ошибка при обращении к серверу', `${++counter}. ${message}`, () => this.toastPresented$.next( false ))
                        .then( t => {
                            t.present(); this.toastPresented$.next( true )
                        })
                        .then(() => {
                            throw e;
                        })
                );
            })
        ) as Observable<ContentToSign>;
    }

    transitEntity$( entkey: string, entid: any, transitid: string, isSilent = false ) {
        let action = {
            context: 'main',
            action: 'transit',
            data: [{
                transit: transitid,
                type: entkey,
                id: entid,
            }]
        }
        let loader: HTMLIonLoadingElement;
        return of( entkey ).pipe(
            mergeMap(() =>
                isSilent
                    ? of( null )
                    : from(
                        this.loading
                            .create({ message: `Смена статуса ${entkey}#${entid} -> ${status}`, cssClass: 'nci-loading' })
                            .then( _loader => {
                                loader = _loader; loader.present();
                            })
                    )
            ),
            mergeMap(() =>
                this.http.post<{
                    data: {
                        error: any,
                        id: string,
                        type: string,
                        next_entity?: { id: string, type: string },
                    }[]
                }>(
                    `/webapi/v1/action/`, action, { headers: JSONHEADERS }
                )
            ),
            map( response => {
                if ( response.data?.[0]) {
                    let report = response.data[0];
                    loader?.dismiss();
                    this.toast
                        .create({
                            header: 'Переход выполнен успешно',
                            message: report.next_entity
                                ? `${entkey}#${entid} -> ${report.next_entity.type}#${report.next_entity.id}`
                                : `${entkey}#${entid} - статус изменён`,
                            duration: 10000,
                            color: 'success',
                            position: 'top',
                            buttons: [{
                                text: 'Хорошо',
                                role: 'cancel',
                                handler: () => this.toastPresented$.next( false )
                            }]
                        })
                        .then( t => {
                            t.present(); this.toastPresented$.next( true )
                        })
                    return report;
                } else throw new Error( 'Неожиданный ответ от сервера (data: null)' );
            }),
            catchError( e => {
                console.log( '[ERROR]', e );
                let message = `Не удалось произвести ${status} для смены статуса сущности ${entkey}#${entid}`;
                if ( e.error ) {
                    if ( e.error.errors ) {
                        if ( e.error.errors instanceof Array )
                            message = e.error.errors.reduce(( acc, v ) =>
                                `${acc ? acc + '; ' : ''
                                }${v.status ? v.status + ': ' : ''
                                }${v.detail || v.code || ''
                                }`
                                , '' );
                        else if ( e.error.errors.error ) {
                            if ( typeof e.error.errors.error === 'string' )
                                message = e.error.errors.error;
                            else
                                message = JSON.stringify( e.error.errors.error );
                        }
                    }
                }
                return from( this.toast
                    .create({
                        header: 'Действие не выполнено',
                        message: `${++counter}. ${message}`,
                        color: 'danger',
                        position: 'top',
                        buttons: [{
                            text: 'Ясно',
                            role: 'cancel',
                            handler: () => this.toastPresented$.next( false )
                        }]
                    })
                    .then( t => {
                        t.present(); this.toastPresented$.next( true )
                    })
                    .then(() => null ).then(() => {
                        throw e;
                    })
                );
            }),
            finalize(() => {
                if ( loader ) loader.dismiss();
            })
        )
    }

    transitEntities$( itemsToTransit: ItemToTransit[], o: {
        transitid?: string,
        message?: string,
        isSilent?: boolean,
        captionErr?: string,
        isGroup?: boolean,
    } = {}): Observable<ItemToTransit[]> {
        let loader: HTMLIonLoadingElement;
        const data = itemsToTransit.map( itemToTransit => {
            let itemData = {
                id: itemToTransit.id,
                type: itemToTransit.type,
                transit: itemToTransit.$transit || o.transitid,
                transit_group: itemToTransit.$transit_groups,
                payload: {} as any
            };
            if ( o.isGroup ) {
                itemToTransit.$transitReport = { status: 'waiting' }
            }
            if ( itemToTransit.$sign ) itemData.payload.sign = itemToTransit.$sign;
            if ( itemToTransit.$file_name ) itemData.payload.file_name = itemToTransit.$file_name;
            if ( itemToTransit.$transitForm ) itemData.payload.form = itemToTransit.$transitForm;
            return itemData;
        });
        this.loading
            .create({ message: `Обработка...`, cssClass: 'nci-loading' })
            .then( _loader => {
                loader = _loader; loader.present();
            })
        return this.commonActRequest$({
            data: {
                context: 'main',
                action: o.isGroup ? 'transit_group' : 'transit',
                data
            },
            ...o
        }).pipe(
            map( response => {
                const reports = response?.data;
                if ( reports.length ) {
                    reports.forEach(( transitReport: { target: string, error: string, [key: string]: any }) => {
                        if ( transitReport.error ) {
                            this.toastService
                                .showToast(
                                    'Ошибка',
                                    transitReport.error,
                                    () => {
                                        if ( !transitReport.target ) {
                                            return
                                        }
                                        this.toastPresented$.next( false );
                                        const target: string = this.errorTargetMap[transitReport.target];
                                        if ( target === 'organization_info' ) {
                                            this.fetchOrgInfo$().subscribe(( orginfo ) => {
                                                this.gotoEntity( target, orginfo.id )
                                            })
                                        } else {
                                            this.gotoEntity( target, this.ids[target]);
                                        }
                                    })
                                .then( t => {
                                    t.present(); this.toastPresented$.next( true )
                                });
                            throw new Error( transitReport.error );
                        }

                        if ( transitReport.transit ) transitReport.$transit = transitReport.transit;
                        const signedItem = itemsToTransit.find( _item => _item.id === transitReport.id && _item.type === transitReport.type );
                        if ( signedItem ) {
                            signedItem.$isSuccess = !transitReport.error;
                            signedItem.$transitReport = transitReport;
                        }
                    });
                    if ( o.isGroup ) return reports as ItemToTransit[]
                }
                itemsToTransit.forEach( item => {
                    if ( !item.$transitReport ) {
                        item.$isSuccess = false;
                        item.$transitReport = {
                            status: 'error',
                            error: 'Некорректный ответ сервера'
                        }
                    }
                })
                return itemsToTransit as ItemToTransit[];
            }),
            catchError( e => {
                itemsToTransit.forEach(( _item: ItemToTransit ) => {
                    _item.$isSuccess = false;
                    if ( e instanceof HttpErrorResponse )
                        _item.$transitReport = {
                            status: 'error',
                            error: e.message,
                            detail: e.error,
                        }
                });
                return itemsToTransit;
            }),
            finalize(() => {
                if ( loader ) loader.dismiss();
            })
        ) as Observable<ItemToTransit[]>;
    }

    // эту часть необходимо пересмотреть и возможно объеденить (entityPrint, actionPrint, signPrint)
    // подумать над более абстрактным объединяющим неймингом
    private actionPrint$(
        action: string,
        entType: string,
        isSilent = false,
        data: any,
    ): Observable<{ blob: Blob, filename: string, mime: string }> {
        const _data = {
            context: 'main',
            action,
            data
        };
        let loader: HTMLIonLoadingElement;
        return of( null ).pipe(
            mergeMap(() => isSilent ? of( null ) : from(
                this.loading
                    .create({ message: `Загрузка печатной формы`, cssClass: 'nci-loading' })
                    .then( _loader => {
                        loader = _loader; loader.present();
                    })
            ),
            ),
            mergeMap(() =>
                this.http.post(
                    '/webapi/v1/action/',
                    _data,
                    { observe: 'response', responseType: 'blob' }
                )
            ),
            map(( resp: HttpResponse<Blob> ) => {
                const contentDisposition = resp.headers.get( 'content-disposition' );
                const filename = contentDisposition.split( '\"' )[1].trim();
                const mime = resp.headers.get( 'content-type' );
                return { blob: resp.body, filename, mime }
            }),
            catchError( e => {
                console.warn( '[SRV][ERROR]', e );
                let message = `Не удалось загрузить печатную форму "${entType ? this.getTerm( 'ui.ent.' + entType + '.title' ) : ''}"`;
                if ( e.error ) {
                    if ( e.error.errors ) {
                        if ( e.error.errors instanceof Array )
                            message = e.error.errors.reduce(( acc, v ) =>
                                `${acc ? acc + '; ' : ''
                                }${v.status ? v.status + ': ' : ''
                                }${v.detail || v.code || ''
                                }`
                                , '' );
                        else if ( e.error.errors.error )
                            message = typeof e.error.errors.error === 'string'
                                ? e.error.errors.error
                                : JSON.stringify( e.error.errors.error );
                    } else {
                        message += ( message ? '; ' : '' ) + e.error;
                    }
                }
                return from( this.toast
                    .create({
                        header: 'Ошибка при обращении к серверу',
                        message: `${++counter}. ${message}`,
                        color: 'danger',
                        position: 'top',
                        buttons: [{
                            text: 'Ясно',
                            role: 'cancel',
                            handler: () => this.toastPresented$.next( false )
                        }]
                    })
                    .then( t => {
                        t.present(); this.toastPresented$.next( true )
                    })
                    .then(() => {
                        throw e;
                    })
                );
            }),
            finalize(() => {
                if ( loader ) loader.dismiss();
            })
        );
    }

    entityPrint$(
        entId: string,
        entType: string,
        stencilFieldKey: string,
        stencilId: string = null,
        isSilent = false
    ): Observable<{ blob: Blob, filename: string, mime: string }> {
        return this.actionPrint$( 'entity_print', entType, isSilent,
            {
                id: entId,
                type: entType,
                field: stencilFieldKey,
                stencil_id: stencilId,
            }
        )
    }

    entityPrint(
        entId: string,
        entType: string,
        stencilFieldKey: string,
        stencilId: string = null,
        isSilent = false
    ) {
        this.entityPrint$( entId, entType, stencilFieldKey, stencilId, isSilent ).subscribe(({ blob, filename }) => {
            const fileUrl = window.URL.createObjectURL( blob );
            fileDownloaderFn( fileUrl, filename );
        });
    }

    signPrint$(
        signIds: string[],
        isSilent = false
    ): Observable<{ blob: Blob, filename: string, mime: string }> {
        return this.actionPrint$( 'sign_print', null, isSilent,
            {
                sign_id: signIds
            }
        )
    }

    signPrint(
        signIds: string[],
        isSilent = false
    ) {
        this.signPrint$( signIds, isSilent ).subscribe(({ blob, filename }) => {
            const fileUrl = window.URL.createObjectURL( blob );
            fileDownloaderFn( fileUrl, filename, blob.type );
        });
    }

    getBlobByUrl$( url: string ): Observable<{ blob: Blob, mime: string }> {
        return of( null ).pipe(
            mergeMap(() =>
                this.http.get(
                    url,
                    { observe: 'response', responseType: 'blob' }
                )
            ),
            map(( resp: HttpResponse<Blob> ) => {
                const mime = resp.headers.get( 'content-type' );
                return { blob: resp.body, mime }
            }),
            catchError( e => {
                console.warn( '[SRV][ERROR]', e );
                let message = `Не удалось загрузить файл`;
                if ( e.error ) {
                    if ( e.error.errors ) {
                        if ( e.error.errors instanceof Array )
                            message = e.error.errors.reduce(( acc, v ) =>
                                `${acc ? acc + '; ' : ''
                                }${v.status ? v.status + ': ' : ''
                                }${v.detail || v.code || ''
                                }`
                                , '' );
                        else if ( e.error.errors.error )
                            message = typeof e.error.errors.error === 'string'
                                ? e.error.errors.error
                                : JSON.stringify( e.error.errors.error );
                    } else {
                        message += ( message ? '; ' : '' ) + e.error;
                    }
                }
                return from( this.toast
                    .create({
                        header: 'Ошибка при обращении к серверу',
                        message: `${++counter}. ${message}`,
                        color: 'danger',
                        position: 'top',
                        buttons: [{
                            text: 'Ясно',
                            role: 'cancel',
                            handler: () => this.toastPresented$.next( false )
                        }]
                    })
                    .then( t => {
                        t.present(); this.toastPresented$.next( true )
                    })
                    .then(() => {
                        throw e;
                    })
                );
            })
        );
    }

    private commonActRequest$<T = any>( o: {
        data: any,
        message?: string,
        isSilent?: boolean,
        captionErr?: string,
    }): Observable<any> {
        const isSilent = o.isSilent || o.isSilent !== false;
        let loader: HTMLIonLoadingElement;
        return of( '' ).pipe(
            mergeMap(() => isSilent || !o.message ? of( null ) : from(
                this.loading
                    .create({ message: o.message || 'Загрузка данных для подписи...', cssClass: 'nci-loading' })
                    .then( _loader => {
                        loader = _loader; loader.present();
                    })
            )),
            mergeMap(() => this.http.post<any>(
                '/webapi/v1/action/',
                o.data,
                { headers: ACTION_HEADERS }
            )),
            catchError( e => {
                console.log( '[SRV][ERROR]', e );
                this.checkStatus( e )
                let message = o.captionErr || '';
                if ( e.error ) {
                    if ( e.error.errors ) {
                        if ( e.error.errors instanceof Array )
                            message = e.error.errors.reduce(( acc, v ) =>
                                `${acc ? acc + '; ' : ''
                                }${v.status ? v.status + ': ' : ''
                                }${v.detail || v.code || ''
                                }`
                                , '' );
                        else if ( e.error.errors.error )
                            message = typeof e.error.errors.error === 'string'
                                ? e.error.errors.error
                                : JSON.stringify( e.error.errors.error );
                    } else {
                        message += ( message ? '; ' : '' ) + e.error;
                    }
                }
                return from( this.toast
                    .create({
                        header: 'Ошибка при обращении к серверу',
                        message: `${++counter}. ${message}`,
                        color: 'danger',
                        position: 'top',
                        buttons: [{
                            text: 'Ясно',
                            role: 'cancel',
                            handler: () => this.toastPresented$.next( false )
                        }]
                    })
                    .then( t => {
                        t.present(), this.toastPresented$.next( true )
                    })
                    .then(() => {
                        throw e;
                    })
                );
            }),
            finalize(() => {
                if ( loader ) loader.dismiss();
            })
        );

    }

    orgInfoDadataUpdateAct$(
        orgInfoId: string
    ): Observable<any> {
        let loader: HTMLIonLoadingElement;
        this.loading
            .create({ message: `Обновление данных`, cssClass: 'nci-loading' })
            .then( _loader => {
                loader = _loader; loader.present();
            })
        return this.commonActRequest$({
            data: {
                context: 'main',
                action: 'org_info_dadata_update',
                data: { id: orgInfoId }
            }
        }).pipe(
            map( response => response?.data || null ),
            finalize(() => {
                if ( loader ) loader.dismiss();
            })
        );
    }

    // а можно переделать через commonEntRequest$ ?
    fetchSummary$( isSilent = true ): Observable<EntData> {
        let loader: HTMLIonLoadingElement;
        return of( '' ).pipe(
            mergeMap(() => isSilent ? of( null ) : from(
                this.loading
                    .create({ message: `Загрузка данных`, cssClass: 'nci-loading' })
                    .then( _loader => {
                        loader = _loader; loader.present();
                    })
            )),
            mergeMap(() => this.http.get<SrvResponse>(
                '/webapi/v1/summary/',
                { headers: HEADERS }
            )),
            map( response => {
                if ( response.data ) {
                    let ent: any = response.data
                    ent.$isVnd = true;
                    ent.$makeup = () => this.makeupEntity( ent );
                    return ent;
                } else return null;
            }),
            finalize(() => {
                if ( loader ) loader.dismiss();
            })
        )
    }

    fetchSchema$( entkey, isSilent = false ): Observable<EntSchema> {
        let entGenitiveLabel = this.getEntLabel( entkey, 'genitive' );
        return this.commonEntRequest$<EntSchema>( 'schema', {
            entkey,
            caption: `Загрузка схемы ${entGenitiveLabel}`,
            captionErr: `Не удалось загрузить схему ${entGenitiveLabel}`,
        }, isSilent );
    }

    fetchMeta$( entkey, isSilent = false ): Observable<EntData> {
        let entGenitiveLabel = this.getEntLabel( entkey, 'genitive' );
        return this.commonEntRequest$<EntData>( 'schema', {
            entkey,
            caption: `Загрузка метаданных ${entGenitiveLabel}`,
            captionErr: `Не удалось загрузить метаданные ${entGenitiveLabel}`,
        }, isSilent );
    }

    fetchOne$<T = EntData>( entkey, entid, isSilent = false, hasErrorToast = true ): Observable<T> {
        let entGenitiveLabel = this.getEntLabel( entkey, 'genitive' );
        return this.commonEntRequest$<T>( 'get', {
            entkey, entid,
            caption: `Загрузка ${this.getEntLabel( entkey, 'genitive' )} №&nbsp;${entid}`,
            captionErr: `Не удалось загрузить ${this.getEntLabel( entkey, 'accusative' )} №&nbsp;${entid}`,
        }, isSilent, hasErrorToast );
    }

    postOne$<T = EntData>( entkey, srvData, isSilent = false ): Observable<T> {
        return this.commonEntRequest$<T>( 'post', {
            entkey,
            data: srvData,
            caption: `Создание ${this.getEntLabel( entkey, 'genitive' )}`,
            captionErr: `Не удалось создать ${this.getEntLabel( entkey, 'accusative' )}`,
            captionSuccess: ( ent ) => `${this.getEntLabel( entkey, 'nom' )} - cоздание выполнено, присвоен №&nbsp;${ent.id}`,
        }, isSilent );
    }

    saveOne$<T = EntData>( entkey, entid, srvData, isSilent = false ): Observable<T> {
        return this.commonEntRequest$<T>( 'patch', {
            entkey, entid,
            data: srvData,
            caption: `Сохранение ${this.getEntLabel( entkey, 'genitive' )} №&nbsp;${entid}`,
            captionErr: `Не удалось сохранить ${this.getEntLabel( entkey, 'accusative' )} №&nbsp;${entid}`,
            captionSuccess: `${this.getEntLabel( entkey, 'nom' )} №&nbsp;${entid} - сохранение выполнено`,
        }, isSilent );
    }

    deleteOne$<T = EntData>( entkey, entid, isSilent = false ): Observable<T> {
        return this.commonEntRequest$<T>( 'delete', {
            entkey, entid,
            caption: `Удаление ${this.getEntLabel( entkey, 'genitive' )} №&nbsp;${entid}`,
            captionErr: `Не удалось удалить ${this.getEntLabel( entkey, 'accusative' )} №&nbsp;${entid}`,
            captionSuccess: `${this.getEntLabel( entkey, 'nom' )} №&nbsp;${entid} - удаление выполнено`,
        }, isSilent );
    }

    fetchAll$<T = EntData[]>( entkey, isSilent = false ): Observable<T> {
        return this.commonEntRequest$<T>( 'get', {
            entkey,
            caption: `Загрузка ${this.getEntLabel( entkey, 'genitive', true )}`,
            captionErr: `Не удалось загрузить ${this.getEntLabel( entkey, 'accusative', true )}`
        }, isSilent );
    }

    fetchTop$<T = EntData[]>( entkey, limit: number, isSilent = false ): Observable<T> {
        return this.commonEntRequest$<T>( 'get', {
            entkey,
            limit,
            caption: `Загрузка ${this.getEntLabel( entkey, 'genitive', true )}`,
            captionErr: `Не удалось загрузить ${this.getEntLabel( entkey, 'accusative', true )}`
        }, isSilent );
    }

    fetchPage$<T = EntData[]>( entkey, offset, limit, params: { [key: string]: string | string[] } = null, isSilent = false ): Observable<T> {
        return this.commonEntRequest$<T>( 'get', {
            entkey, offset, limit,
            caption: `Загрузка ${this.getEntLabel( entkey, 'genitive', true )}`,
            captionErr: `Не удалось загрузить ${this.getEntLabel( entkey, 'accusative', true )}`,
            params
        }, isSilent );
    }

    fetchPortion$<T = EntData[]>( entkey, start, limit, isSilent = true ): Observable<T> {
        return this.commonEntRequest$<T>( 'get', {
            entkey,
            caption: `Загрузка ${this.getEntLabel( entkey, 'genitive', true )}`,
            captionErr: `Не удалось загрузить ${this.getEntLabel( entkey, 'accusative', true )}`,
            start, limit
        }, isSilent );
    }

    fetchQueried$<T = EntData[]>( entkey, query, params: { [key: string]: string | string[] } = null, isSilent = true, urlApi?, queryKey?: string ): Observable<T> {
        return this.commonEntRequest$<T>( 'get', {
            entkey,
            caption: `Загрузка выборки ${this.getEntLabel( entkey, 'genitive', true )}`,
            captionErr: `Не удалось загрузить выборку ${this.getEntLabel( entkey, 'accusative', true )}`,
            query,
            params,
            urlApi,
            queryKey
        }, isSilent );
    }

    getList$( listkey: string ): Observable<any[]> | undefined {
        return __cash$[`all.${listkey}`];
    }

    fetchDct$( dctkey: string, fetchAll = true, isSilent = false ): Observable<any[]> {
        let dctItemsLabel = this.getEntLabel( dctkey, 'genitive', true );
        const o: any = {
            entkey: `dict/${dctkey}`,
            caption: `Загрузка словаря ${dctItemsLabel}`,
            captionErr: `Не удалось загрузить словарь ${dctItemsLabel}`
        };
        if ( fetchAll ) o.limit = MAX_PAGE_SIZE;
        return this.commonEntRequest$<any[]>( 'get', o, isSilent ).pipe(
            map( list => {
                __cash$[`all.${dctkey}`].next( list );
                __cash$[`dct.${dctkey}`].next(
                    list.reduce(
                        ( acc, item ) => {
                            acc[item.id] = item.$makeup().$snapshot; return acc;
                        }
                        , {})
                );
                return list;
            })
        );
    }

    fetchOneDct$<T>( dctkey, itemId, isSilent = false ): Observable<T> {
        let dctItemsLabel = this.getEntLabel( dctkey, 'genitive', true );
        return this.commonEntRequest$<T>( 'get', {
            entkey: `dict/${dctkey}`,
            entid: itemId,
            caption: `Загрузка элемента словаря ${dctItemsLabel}`,
            captionErr: `Не удалось загрузить элемент словаря ${dctItemsLabel}`
        }, isSilent );
    }

    fetchStaticDcts$( isSilent = false ): Observable<boolean> {
        return this.commonEntRequest$<any[]>( 'get', {
            entkey: 'dict/static',
            caption: `Загрузка статичных справочников`,
            captionErr: `загрузить статичные справочники`
        }, isSilent ).pipe(
            map( list => {
                list.map( dict => {
                    if ( __cash$[`dct.${dict.type}`]) {
                        __cash$[`all.${dict.type}`].next( dict.items );
                        __cash$[`dct.${dict.type}`].next( dict.items.reduce(
                            ( acc, item ) => {
                                acc[item.id] = item; return acc;
                            }, {})
                        );
                    } else {
                        console.warn( '[!] Незаявленный статичный словарь:', dict.type );
                    }
                });
                return true;
            }),
            catchError( e => of( false ))
        );
    }

    fetchSomething$<T = EntData[]>( o: {
        endpoint?: string,
        entkey?: string,
        entid?: any,
        data?: any,
        query?: string,
        start?: number,
        limit?: number,
        offset?: number,
        search?: string,
        caption?: string,
        captionErr?: string,
        captionSuccess?: string | (( ent ) => string ),
        params?: { [key: string]: string | string[] }
    }, isSilent = false, hasErrorToast = true ): Observable<T> {
        o.caption = o.caption || 'Загрузка данных';
        o.captionErr = o.captionErr || 'Не удалось загрузить некоторые данные с сервера';
        o.entkey = o.endpoint || o.entkey;
        return this.commonEntRequest$<T>( 'get', o as any, isSilent, hasErrorToast );
    };

    private commonEntRequest$<T = EntData>( method: 'post' | 'get' | 'delete' | 'put' | 'patch' | 'schema', o: {
        entkey: string,
        entid?: any,
        data?: any,
        query?: string,
        start?: number,
        limit?: number,
        offset?: number,
        search?: string,
        caption?: string,
        captionErr?: string,
        captionSuccess?: string | (( ent ) => string ),
        params?: { [key: string]: string | string[] },
        urlApi?: string,
        queryKey?: string
    }, isSilent = false, hasErrorToast = true ): Observable<T> {
        if ( !o.caption ) o.caption = 'Обмен данными к сервером';
        if ( !o.captionErr ) o.captionErr = 'Не удалось выполнить запрос';
        const endpointKey = o.entkey;
        const urlApi = o.urlApi || 'webapi';
        let url = o.entid
            ? `/${urlApi}/v1/${endpointKey}/${o.entid}/`
            : `/${urlApi}/v1/${endpointKey}/`;

        const paramsObj = o.params || {};
        o.offset !== undefined
            ? paramsObj['page[offset]'] = String( o.offset ) : null;

        o.limit !== undefined
            ? paramsObj['page[limit]'] = String( o.limit ) : null;

        o.start !== undefined
            ? paramsObj['start'] = String( o.start ) : null;

        if ( o.queryKey !== undefined ){
            paramsObj[o.queryKey] = o.query
        }else{
            o.query !== undefined
            ? paramsObj['query'] = o.query : null;
        }

        o.search !== undefined
            ? paramsObj['search'] = o.search : null;

        method === 'schema'
            ? paramsObj['page[limit]'] = '0' : null;

        const params = new HttpParams({ fromObject: paramsObj });

        let loader: HTMLIonLoadingElement;
        return of( endpointKey ).pipe(
            mergeMap(() => isSilent ? of( null ) : from(
                this.loading
                    .create({ message: o.caption, cssClass: 'nci-loading' })
                    .then( _loader => {
                        loader = _loader; loader.present();
                    })
            )),
            mergeMap(() => {
                switch ( method ) {
                    case 'get': return this.http.get<SrvResponse>( url, { headers: HEADERS, params });
                    case 'put': return this.http.put<SrvResponse>( url, { data: o.data }, { headers: HEADERS });
                    case 'post': return this.http.post<SrvResponse>( url, { data: o.data }, { headers: HEADERS });
                    case 'patch': return this.http.patch<SrvResponse>( url, { data: o.data }, { headers: HEADERS });
                    case 'delete': return this.http.delete<SrvResponse>( url, { headers: HEADERS });
                    case 'schema': return this.http.request( 'get', url, { headers: HEADERS, params });
                    default:
                        console.warn( `An unknown server request method: ${method}` );
                        return of( null );
                }
            }),
            map(( response: SrvResponse ) => {
                if ( response?.included ) {
                    this.updateCash( response.included );
                }
                if ( response?.data ) {
                    let result: any | any[] = response.data instanceof Array
                        ? response.data.map( ent => this.refineEnt( ent, o ))
                        : this.refineEnt( response.data, o );

                    if ( o.captionSuccess ) {
                        this.toast
                            .create({
                                message: typeof o.captionSuccess === 'function' ? o.captionSuccess( result ) : o.captionSuccess,
                                duration: 700,
                                color: 'success',
                            }).then( t => t.present())
                    }
                    result.$meta = response.meta || response.data['meta'];
                    return result;
                } else {
                    return null;
                }
            }),
            catchError( e => {
                console.log( '[SRV][ERROR]', e );
                this.checkStatus( e )
                let message = o.captionErr;
                if ( e.error ) {
                    if ( e.error.errors ) {
                        if ( e.error.errors instanceof Array )
                            message = e.error.errors.reduce(( acc, v ) =>
                                `${acc ? acc + '<br/>' : ''
                                }${v.status ? v.status + ': ' : ''
                                }${v.detail || v.code || ''
                                }`
                                , '' );
                        else if ( e.error.errors.error )
                            message = typeof e.error.errors.error === 'string'
                                ? e.error.errors.error
                                : JSON.stringify( e.error.errors.error );
                    }
                }
                if ( !hasErrorToast ) return throwError( e )
                else return from( this.toast
                    .create({
                        header: 'Ошибка при обращении к серверу',
                        message: `${++counter}. ${message}`,
                        color: 'danger',
                        position: 'top',
                        buttons: [{
                            text: 'Ясно',
                            role: 'cancel',
                            handler: () => this.toastPresented$.next( false )
                        }]
                    })
                    .then( t => {
                        t.present(); this.toastPresented$.next( true )
                    })
                    .then(() => {
                        throw e;
                    })
                );
            }),
            finalize(() => {
                if ( loader ) loader.dismiss();
            })
        )
    }

    private refineEnt( ent, o ) {
        ent.$isVnd = true;
        ent.$type = ent.type || o.entkey;
        ent.$makeup = () => this.makeupEntity( ent );
        return ent;
    }

    private updateCash( items ) {
        if ( items ) items.forEach( item => {
            __cash[`${item.type}#${item.id}`] = item;
        });
    }

    private makeupEntity( ent: EntData ): EntData {
        if ( ent.$snapshot ) return ent;
        else ent.$snapshot = { id: ent.id, ...ent.attributes, ...ent.meta };

        if ( ent.relationships ) Object
            .keys( ent.relationships )
            .forEach( propKey => {
                let propData = ent.relationships[propKey].data;
                if ( propData instanceof Array )
                    propData = propData.map( item => this.makeupItem( item ))
                else if ( propData )
                    propData = this.makeupItem( propData );
                ent.$snapshot[propKey] = propData;
            });

        if ( ent.meta ) {
            if ( ent.meta.allow_actions ) {
                ent.$actions = ent.meta.allow_actions;
                ent.$canView = !!~ent.$actions.indexOf( 'view' );
                ent.$canEdit = !!~ent.$actions.indexOf( 'change' );
                ent.$canRemove = !!~ent.$actions.indexOf( 'delete' );
            }
            if ( ent.meta.allow_transits ) ent.$transits = ent.meta.allow_transits;
            if ( ent.meta.allow_transit_groups ) ent.$transit_groups = ent.meta.allow_transit_groups;
            if ( ent.meta.versions ) {
                ent.$versions = ent.meta.versions;
                ent.$versions.forEach( version => {
                    if ( version.is_active ) {
                        version.$isActive = true;
                    }
                    if ( version.id === ent.id ) {
                        version.$isCurrent = true;
                        version.$isOpened = true;
                    }
                })
            }
            if ( ent.meta.signatures ) ent.$signatures = ent.meta.signatures;
        }
        if ( ent.attributes && ent.attributes.is_active !== undefined )
            ent.$role = ent.attributes.is_active ? 'active' : 'archived';

        return ent;
    }

    private makeupItem( item ) {
        let relatedEnt = __cash[`${item.type}#${item.id}`];
        let madeupEnt = relatedEnt
            ? {
                ...relatedEnt,
                $makeup: () => this.makeupEntity( madeupEnt ),
                $isVnd: true
            } : {
                ...item,
                $isRelation: true
            };

        return madeupEnt;
    }

    private getEntLabel( entkey: string, termCase: LanguageCases = 'nom', isPlural: boolean = false ): string {
        let term = this.getTerm ? this.getTerm( `ui.ent.${entkey}.lbl.${termCase}${isPlural ? '.plural' : ''}` ) : undefined;
        if ( !term ) {
            term = this.getTerm ? this.getTerm( `ui.ent.${entkey}.lbl.nom` ) : undefined;
            if ( term )
                term = {
                    nom: [`Данные модели "${term}"`, `Данные моделей "${term}"`],
                    genitive: [`Данных модели "${term}"`, `Данных моделей "${term}"`],
                    dative: [`Данным модели "${term}"`, `Данным моделей "${term}"`],
                    accusative: [`Данные модели "${term}"`, `Данные моделей "${term}"`],
                    ablative: [`Данными модели "${term}"`, `Данными моделей "${term}"`],
                    prepositional: [`Данных модели "${term}"`, `Данных моделей "${term}"`],
                }[termCase][isPlural ? 1 : 0]

            if ( !term ) term = isPlural ? `"${entkey}-list"` : `"${entkey}"`;
        }

        return term;
    }

    public dismissAllToast() {
        if ( this.toastPresented$.value ) {
            this.toast.dismiss();
            this.toastPresented$.next( false )
        }
    }

    private taskWatcher$( taskId: string ): Observable<EntData> {
        return new Observable<EntData>( sub => {
            const startInterval = 1500;
            const maxAttempts = 130;
            // const minInterval = 1000;
            // const maxInterval = 30000;

            // let mode: 'incremental' | 'decremental' = 'incremental';
            // let prcDec = 0;

            let curAttempt = 0;
            let interval = 3000; // startInterval;
            // let maximumCurrentInterval = interval;
            let watcherSub: Subscription;

            this.toast
                .create({
                    header: `Прервать выполнение задачи`,
                    // duration: 10000,
                    color: 'primary',
                    position: 'top',
                    buttons: [{
                        text: 'Прервать',
                        handler: () => {
                            this.commonRequestInterrupt$( 'task', taskId ).subscribe(() => console.log( '[DEV] Задача прервана' ))
                            this.toastPresented$.next( false );
                        }
                    }, {
                        text: 'Скрыть',
                        role: 'cancel',
                        handler: () => this.toastPresented$.next( false )
                    }]
                })
                .then( t => {
                    t.present(); this.toastPresented$.next( true )
                })

            const tsk = () => {
                watcherSub = timer( interval ).pipe(
                    switchMap(() => this.fetchOne$( 'task', taskId, true )),
                ).subscribe( task => {
                    if ( ++curAttempt > maxAttempts ) {
                        return sub.error( 'Превышено чисто попыток обращения к статусу задачи' );
                    }
                    if ( task.attributes?.interrupt ) return sub.error( 'interrupt' );

                    // interval = ( interval + ( interval / 2 ) );
                    // interval = ( interval * 2 );

                    // if( interval > maxInterval ) interval = maxInterval;
                    // else if( interval < minInterval ) interval = minInterval;

                    switch ( task.attributes.state ) {
                        case 'done':
                            sub.next( task );
                            this.dismissAllToast();
                            return sub.complete();

                        case 'running':
                        case 'starting':
                        case 'waiting':
                            sub.next( task );
                            return tsk();

                        default:
                            return sub.error( task );
                    }
                });
            };
            // Run task in First Time
            tsk();

            return () => watcherSub.unsubscribe();
        })
    }

    private reportAct$( reportId: string, isSilent ): Observable<IActTask> {
        let data = { report_id: reportId }
        return this.analyticTaskAct$({
            action: 'report_build_xlsx',
            actData: data,
            message: 'Формирование отчета'
        }, isSilent )
    }

    fetchTask$( taskId: string ): Observable<EntData> {
        return this.taskWatcher$( taskId ).pipe(
            filter( ent => ent.attributes.state && ent.attributes.state === 'done' ),
            catchError( e => {
                console.warn( '[SRV][ERROR]', e );
                if ( e === 'interrupt' ) return throwError( e );
                let message = e === 'interrupt' ? 'Выполнение задачи было прервано' : `Задача завершилась с ошибкой`;
                if ( e.error ) {
                    if ( e.error.errors ) {
                        if ( e.error.errors instanceof Array )
                            message = e.error.errors.reduce(( acc, v ) =>
                                `${acc ? acc + '; ' : ''
                                }${v.status ? v.status + ': ' : ''
                                }${v.detail || v.code || ''
                                }`
                                , '' );
                        else if ( e.error.errors.error )
                            message = typeof e.error.errors.error === 'string'
                                ? e.error.errors.error
                                : JSON.stringify( e.error.errors.error );
                    } else {
                        message += ( message ? '; ' : '' ) + e.error;
                    }
                }
                return from( this.toast
                    .create({
                        header: 'Ошибка при обращении к серверу',
                        message,
                        // duration: 10000,
                        color: 'danger',
                        position: 'top',
                        buttons: [{
                            text: 'Ясно',
                            role: 'cancel',
                            handler: () => this.toastPresented$.next( false )
                        }]
                    })
                    .then( t => {
                        t.present(), this.toastPresented$.next( true )
                    })
                    .then(() => {
                        throw e;
                    })
                );
            })
        )
    }

    fetchReportTask$( reportId, isSilent = false ): Observable<EntData> {
        let loader: HTMLIonLoadingElement;
        if ( !isSilent )
            this.loading
                .create({ message: `Мониторинг выполнения задачи`, cssClass: 'nci-loading' })
                .then( _loader => {
                    loader = _loader; loader.present();
                })
        return this.reportAct$( reportId, isSilent )
            .pipe(
                switchMap( actTask =>
                    this.fetchTask$( actTask.task_id )
                ),
                finalize(() => {
                    if ( loader ) loader.dismiss();
                })
            );
    }

    analyticTaskAct$( o: {
        action: string,
        actData: any,
        message?: string
    }, isSilent ): Observable<IActTask> {
        let loader: HTMLIonLoadingElement;
        if ( !isSilent )
            this.loading
                .create({ message: o.message || `Обработка...`, cssClass: 'nci-loading' })
                .then( _loader => {
                    loader = _loader; loader.present();
                })
        return this.commonActRequest$({
            data: {
                context: 'analytic',
                action: o.action,
                data: o.actData
            }, isSilent: false
        }).pipe(
            map( response => response ? response.data : null ),
            finalize(() => {
                if ( loader ) loader.dismiss();
            })
        ) as Observable<IActTask>;
    }

    commonRequestInterrupt$( entKey: string, entId: string ): Observable<EntData> {
        return this.commonEntRequest$( 'patch', { // так как приватная
            entkey: entKey,
            entid: entId,
            data: {
                type: entKey,
                id: entId,
                attributes: { interrupt: true }
            }
        })
    }

    private commonTrackingRequest$<T = any>( o: {
        entkey: string,
        entid?: any,
        params?: { [key: string]: string | string[] },
        query?: string,
        start?: number,
        limit?: number,
        offset?: number,
        search?: string,
        caption?: string,
        captionErr?: string,
        captionSuccess?: string | (( ent ) => string ),
        message?: string,
        isSilent?: boolean,
    }): Observable<any> {
        const isSilent = o.isSilent || o.isSilent !== false;
        if ( !o.caption ) o.caption = 'Обмен данными к сервером';
        if ( !o.captionErr ) o.captionErr = 'Не удалось выполнить запрос';
        let url = o.entid
            ? `/webapi/v1/tracking/${o.entkey}/${o.entid}/`
            : `/webapi/v1/tracking/${o.entkey}/`;

        const paramsObj = o.params || {};
        o.offset !== undefined
            ? paramsObj['page[offset]'] = String( o.offset ) : null;

        o.limit !== undefined
            ? paramsObj['page[limit]'] = String( o.limit ) : null;

        o.start !== undefined
            ? paramsObj['start'] = String( o.start ) : null;

        o.query !== undefined
            ? paramsObj['query'] = o.query : null;

        o.search !== undefined
            ? paramsObj['search'] = o.search : null;
        const params = new HttpParams({ fromObject: paramsObj });
        let loader: HTMLIonLoadingElement;
        return of( '' ).pipe(
            mergeMap(() => isSilent || !o.message ? of( null ) : from(
                this.loading
                    .create({ message: o.message || 'Загрузка данных...', cssClass: 'nci-loading' })
                    .then( _loader => {
                        loader = _loader; loader.present();
                    })
            )),
            mergeMap(() => this.http.get<any>( url, { headers: ACTION_HEADERS, params })),
            catchError( e => {
                console.log( '[SRV][ERROR]', e );
                this.checkStatus( e )
                let message = o.captionErr || '';
                return from( this.toast
                    .create({
                        header: 'Ошибка при обращении к серверу',
                        message: `${++counter}. ${message}`,
                        color: 'danger',
                        position: 'top',
                        buttons: [{
                            text: 'Ясно',
                            role: 'cancel',
                            handler: () => this.toastPresented$.next( false )
                        }]
                    })
                    .then( t => {
                        t.present(), this.toastPresented$.next( true )
                    })
                    .then(() => {
                        throw e;
                    })
                );
            }),
            finalize(() => {
                if ( loader ) loader.dismiss();
            })
        );

    }

    fetchTrackingQueried$<T = EntData[]>( entkey, query, params: { [key: string]: string | string[] } = null, isSilent = false ): Observable<T> {
        return this.commonTrackingRequest$<T>({
            entkey,
            caption: `Загрузка выборки ${this.getEntLabel( entkey, 'genitive', true )}`,
            captionErr: `Сервис телеметрии в данное время недоступен, повторите запрос позже`,
            query,
            params,
            isSilent
        });
    }

    postTrackingAct$(
        barcode: string,
        isSilent = false
    ): Observable<PostTrackingData[]> {
        let loader: HTMLIonLoadingElement;
        let data = {
            barcode
        }
        this.loading
            .create({ message: `Получение данных почтового отправления`, cssClass: 'nci-loading' })
            .then( _loader => {
                loader = _loader; loader.present();
            })
        return this.commonActRequest$({
            data: {
                context: 'main',
                action: 'pochta_tracking',
                data,
            }, isSilent, captionErr: 'Неверный ШПИ'
        }).pipe(
            map( response => response ? response.data : null ),
            finalize(() => {
                if ( loader ) loader.dismiss();
            })
        ) as Observable<PostTrackingData[]>;
    }

    xlsxToDocxReportAct$( analytic_report_fo_id, taskId ): Observable<{ blob: Blob, filename: string }> {
        let data = {
            analytic_report_fo_id,
            task_id: taskId
        }
        return of( null ).pipe(
            mergeMap(() =>
                this.http.post(
                    '/webapi/v1/action/',
                    {
                        context: 'analytic',
                        action: 'report_fo_docx',
                        data,
                    },
                    { observe: 'response', responseType: 'blob' }
                )
            ),
            map(( resp: HttpResponse<Blob> ) => {
                const contentDisposition = resp.headers.get( 'content-disposition' );
                const filename = contentDisposition.split( '\"' )[1].trim();
                const mime = resp.headers.get( 'content-type' );
                return { blob: resp.body, filename, mime }
            }),
            tap(({ blob, filename }) => {
                // console.log('[DEBUG]', filename, blob)
                const fileUrl = window.URL.createObjectURL( blob );
                fileDownloaderFn( fileUrl, filename );
            }),
            catchError( e => {
                console.warn( '[SRV][ERROR]', e );
                let message = `Не удалось загрузить файл`;
                if ( e.error ) {
                    if ( e.error.errors ) {
                        if ( e.error.errors instanceof Array )
                            message = e.error.errors.reduce(( acc, v ) =>
                                `${acc ? acc + '; ' : ''
                                }${v.status ? v.status + ': ' : ''
                                }${v.detail || v.code || ''
                                }`
                                , '' );
                        else if ( e.error.errors.error )
                            message = typeof e.error.errors.error === 'string'
                                ? e.error.errors.error
                                : JSON.stringify( e.error.errors.error );
                    } else {
                        message += ( message ? '; ' : '' ) + e.error;
                    }
                }
                return from( this.toast
                    .create({
                        header: 'Ошибка при обращении к серверу',
                        message: `${++counter}. ${message}`,
                        color: 'danger',
                        position: 'top',
                        buttons: [{
                            text: 'Ясно',
                            role: 'cancel',
                            handler: () => this.toastPresented$.next( false )
                        }]
                    })
                    .then( t => {
                        t.present(); this.toastPresented$.next( true )
                    })
                    .then(() => {
                        throw e;
                    })
                );
            })
        );
    }

    nextInstanceVerificationAct$(
        entKey: string,
        error_message?: string
    ): Observable<any> {
        let loader: HTMLIonLoadingElement;
        this.loading
            .create({ message: `Получение следующего объекта валидации`, cssClass: 'nci-loading' })
            .then( _loader => {
                loader = _loader; loader.present();
            })
        return this.commonActRequest$({
            data: {
                context: 'main',
                action: 'get_next_instance_verification',
                data: { type: entKey }
            }
        }).pipe(
            map( response => {
                const term = this.getTerm( `ui.ent.${entKey}.lbl.genitive.plural` )
                if ( response.data.status && response.data.status === 'not_found' ) {
                    let message = error_message ? error_message : `Нет ${term} в очереди`;
                    return from( this.toast
                        .create({
                            message,
                            color: 'warning',
                            position: 'top',
                            buttons: [{
                                text: 'Ясно',
                                role: 'cancel',
                                handler: () => this.toastPresented$.next( false )
                            }]
                        })
                        .then( t => {
                            t.present(); this.toastPresented$.next( true )
                        })
                    );
                } else
                    return response ? response.data : null
            }),
            catchError( e => {
                console.log( '[SRV][ERROR]', e );
                this.checkStatus( e )
                let message = e.message ? `${e.message}` : '';
                if ( e.error ) {
                    if ( e.error.errors ) {
                        if ( e.error.errors instanceof Array )
                            message = e.error.errors.reduce(( acc, v ) =>
                                `${acc ? acc + '<br/>' : ''
                                }${v.status ? v.status + ': ' : ''
                                }${v.detail || v.code || ''
                                }`
                                , '' );
                        else if ( e.error.errors.error )
                            message = typeof e.error.errors.error === 'string'
                                ? e.error.errors.error
                                : JSON.stringify( e.error.errors.error );
                    }
                } else return from( this.toast
                    .create({
                        header: 'Ошибка при обращении к серверу',
                        message: `${++counter}. ${message}`,
                        color: 'danger',
                        position: 'top',
                        buttons: [{
                            text: 'Ясно',
                            role: 'cancel',
                            handler: () => this.toastPresented$.next( false )
                        }]
                    })
                    .then( t => {
                        t.present(); this.toastPresented$.next( true )
                    })
                    .then(() => {
                        throw e;
                    })
                );
            }),
            finalize(() => {
                if ( loader ) loader.dismiss();
            })
        );
    }

    private checkStatus( e ) {
        if ( e.status === '401' )
            this.toast
                .create({
                    header: 'Возможно ваша сессия устарела.',
                    message: 'Обновите страницу, или пройдите авторизацию ещё раз.',
                    color: 'warning',
                    position: 'top',
                    buttons: [{
                        text: 'Хорошо',
                        role: 'cancel',
                        handler: () => location.reload()
                    }]
                })
                .then( t => t.present());
    }

    public gotoEntity( type: string, id: number | string ) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                ent: 'edit',
                key: type,
                id,
            }, queryParamsHandling: 'merge'
        });
    }

    private fetchOrgInfo$(){
        return this.fetchSomething$({
                entkey: 'organization_info', limit: 1,
                params: { organization: this.profile?.organizationRootId }
            }).pipe(
                map( list => list?.[0] ?? null )
            )
    }
}
