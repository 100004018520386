import { Component, HostBinding } from '@angular/core';

import { Platform, MenuController } from '@ionic/angular';
import { AppService, IAppLayer } from './app.service';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent {
    @HostBinding( 'style.--nci-card-border' ) get getCardBorder(){
        return this.app.visualStyle.hasUIElement_frames ? '1px solid var(--ion-color-step-150, #d9d9d9)' : 'none';
    }
    @HostBinding( 'style.--nci-card-border-radius' ) get getCardBorderRadius(){
        return this.app.visualStyle.hasUIElement_frames ? '4px' : '0';
    }
    constructor(
        private platform: Platform,
        public app: AppService,
        private menu: MenuController,
        private titleService: Title,
    ){
        this.initializeApp();
    }

    layers$ = this.app.layersSubject$;

    focusOnLayer( layer: IAppLayer, layers: IAppLayer[]){
        // console.log('[DEV][HOVERON]', layer);
        for( let i = layers.indexOf( layer )+1; i < layers.length; i++ ){
            let aboveLayer = layers[i];
            if( aboveLayer.modal ){
                let wrapper = aboveLayer.modal.getElementsByClassName( 'modal-wrapper' );
                if( wrapper && wrapper[0])
                    wrapper[0].style.display = 'none';
            }
        }
        layer.focused = true;
    }
    focusOnTopLayer( layers: IAppLayer[]){
        layers.forEach( layer =>{
            layer.focused = false;
            if( layer.modal ){
                let wrapper = layer.modal.getElementsByClassName( 'modal-wrapper' );
                if( wrapper && wrapper[0])
                    wrapper[0].style.display = 'inherit';
            }
        });
    }

    tryToStepBackToLayer( layer: IAppLayer, layers: IAppLayer[]){
        // console.log('[DEV][GOTO_LAYER]', layer);
        let topLayerId = layers.length - 1;
        let layerId = layers.indexOf( layer );
        if( ~layerId && topLayerId > layerId )
            this.tryToCloseLayer( topLayerId, layerId, layers );
        layer.selected = true;
    }

    private tryToCloseLayer( layerId: number, selectedLayerId: number, layers: IAppLayer[]){
        let layer = layers[layerId];
        if( layer.modal && layer.modal.componentProps && layer.modal.componentProps )
            layer.modal.componentProps.closer(()=>{
                layers.splice( layerId, 1 );
                if( layerId - 1 > selectedLayerId ) this.tryToCloseLayer( layerId-1, selectedLayerId, layers )
            });
    }

    tryToCloseTopLayer( layers: IAppLayer[]){
        let topLayerId = layers.length - 1;
        let topLayerSelectedId: number;
        if( layers.length > 1 ){
            topLayerSelectedId = topLayerId - 1;
            layers[topLayerSelectedId].selected = true;
        }else
            topLayerSelectedId = topLayerId;
        if( topLayerId )
            this.tryToCloseLayer( topLayerId, topLayerSelectedId, layers );
    }

    initializeApp() {
        this.platform.ready().then(()=>{
            console.log( 'initializeApp' );
            this.setDocTitle( this.app.visualEntranceStyle.tabTitle ? this.app.visualEntranceStyle.tabTitle : 'FGIS' )
        });
    }

    ionViewWillEnter(){
        console.log( 'app ionViewWillEnter' )
    }

    openFirst() {
        this.menu.enable( true, 'first' );
        this.menu.open( 'first' );
    }

    openEnd() {
        this.menu.open( 'end' );
    }

    openCustom() {
        this.menu.enable( true, 'custom' );
        this.menu.open( 'custom' );
    }

    setDocTitle( title: string ){
        this.titleService.setTitle( title );
    }
    getLayerIcon( layer: IAppLayer ): string{
        return layer.icon
    }
}
