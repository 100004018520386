import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { AppService } from '../app.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoadingController, ToastController } from '@ionic/angular';
import { switchMap, catchError, finalize, tap } from 'rxjs/operators';
import { AuthEsia, AuthPass, AuthType } from '../srv.types';

const VND_HEADERS = new HttpHeaders()
    .set("Content-Type", "application/vnd.api+json")
    .set("Accept", "application/vnd.api+json")

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(
        private app: AppService,
        private http: HttpClient,
        private loading: LoadingController,
        private toast: ToastController,
    ){}

    authByPass$(
        username:string, password:string,
        o:{
            mainMsg:string,
            errMsg?: string,
            hasNoty?:boolean,
        }
    ): Observable<AuthPass> {
        console.log('[NEW-AUTH]')
        let loader:HTMLIonLoadingElement;
        let isSuccess:boolean = false;
        return from(this.loading.create({message: o.mainMsg, cssClass: 'nci-loading'})).pipe(
            switchMap(()=>{
                return this.http.post<AuthPass>(
                    '/webapi/v1/auth/',
                    { username, password },
                );
            }),
            tap(authRe =>{
                this.app.token_integration = authRe.data.token_integration
                console.log('[AUTH][SRV] ->', authRe);
            }),
            catchError(e =>{
                console.log('[ERROR]', e);
                let message = o.errMsg || 'Сервис недоступен';
                if( e.error ){
                    if( e.error.errors ){
                        message = e.error.errors.reduce(( acc, v )=>
                            `${
                                acc ? acc+'; ' : ''
                            }${
                                v.status ? v.status+': ' : ''
                            }${
                                v.detail || v.code || ''
                            }`
                        ,'');
                    }
                }
                if( o.hasNoty )
                    return from(this.toast
                        .create({
                            header: 'Ошибка доступа',
                            message: `${message}`,
                            color: 'danger',
                            position: 'middle',
                            buttons: [{
                                text: 'Ясно',
                                role: 'cancel'
                            }]
                        })
                        .then( t => t.present() )
                        .then(()=>{ throw e })
                    );
                else throw e;
            }),
            finalize(()=>{
                if( loader ) loader.dismiss();
            })
        )
    }

    getAuthState$(o:{
        mainMsg:string,
        hasNoty?:boolean,
        errMsg?:string
    }): Observable<AuthEsia> {
        console.log('[GET-AUTH]')
        let loader:HTMLIonLoadingElement;
        let isSuccess:boolean = false;
        return from(this.loading.create({message: o.mainMsg, cssClass: 'nci-loading'})).pipe(
            switchMap(_loader =>{
                loader = _loader;
                loader.present();
                return this.http.get<AuthEsia>('/webapi/v1/auth/');
            }),
            tap(authRe =>{
                this.app.token_integration = authRe.data.token_integration
                console.log('[ESIA][SRV] ->', authRe);
            }),
            catchError(e =>{
                console.log('[ERROR]', e);
                let message = o.errMsg || 'Сервис недоступен';
                if( e.error ){
                    if( e.error.errors ){
                        message = e.error.errors.reduce(( acc, v )=>
                            `${
                                acc ? acc+'; ' : ''
                            }${
                                v.status ? v.status+': ' : ''
                            }${
                                v.detail || v.code || ''
                            }`
                        ,'');
                    }
                }
                if( o.hasNoty )
                    return from(this.toast
                        .create({
                            header: 'Ошибка доступа',
                            message: `${message}`,
                            color: 'danger',
                            position: 'middle',
                            buttons: [{
                                text: 'Ясно',
                                role: 'cancel'
                            }]
                        })
                        .then( t => t.present() )
                        .then(()=>{ throw e })
                    );
                else throw e;
            }),
            finalize(()=>{
                if( loader ) loader.dismiss();
            })
        )
    }

    getAuthType$(o:{
        mainMsg:string,
        hasNoty?:boolean,
        errMsg?:string
    }): Observable<AuthType>{
        console.log('[GET-AUTH][TYPE]');
        let loader:HTMLIonLoadingElement;
        return from(this.loading.create({message: o.mainMsg, cssClass: 'nci-loading'})).pipe(
            switchMap(() => this.http.get<AuthType>('/webapi/v1/env/')),
            catchError(e =>{
                console.warn('[ERROR]', e);
                let message = o.errMsg || 'Сервис недоступен';
                if( e.error ){
                    if( e.error.errors ){
                        message = e.error.errors.reduce(( acc, v )=>
                            `${
                                acc ? acc+'; ' : ''
                            }${
                                v.status ? v.status+': ' : ''
                            }${
                                v.detail || v.code || ''
                            }`
                        , '');
                    }
                }
                if( o.hasNoty )
                    return from(this.toast
                        .create({
                            header: 'Ошибка доступа',
                            message: `${message}`,
                            color: 'danger',
                            position: 'middle',
                            buttons: [{
                                text: 'Ясно',
                                role: 'cancel'
                            }]
                        })
                        .then( t => t.present() )
                        .then(()=>{ throw e })
                    );
                else throw e;
            }),
            finalize(()=>{
                if( loader ) loader.dismiss();
            })
        );
    }
}
