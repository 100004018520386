import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AppService } from '../app.service';
import { map, catchError } from 'rxjs/operators';
import { NavController } from '@ionic/angular';
import { AuthEsia } from '../srv.types';
import { AuthService } from '../providers/auth.service';

let counter = 0;
@Injectable({
    providedIn: 'root'
})
export class EsiaAuthGuard implements CanActivate, CanLoad {
    constructor(
        private app: AppService,
        private nav: NavController,
        private authSe: AuthService,
    ){}

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivate();
    }

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        console.log('[ESIA-AUTH-GUARD]')
        let loader:HTMLIonLoadingElement;
        let isSuccess:boolean = false;
        return this.authSe.getAuthState$({
            mainMsg:'Получение доступа через портал ГосУслуг...',
            errMsg:'Доступ запрещен',
            hasNoty:true,
        }).pipe(
            map(authRe =>{
                console.log('[ESIA][SRV] ->', authRe);
                return this.checkAuth(authRe);
            }),
            catchError(e =>{
                console.log('[ERROR]', e);
                return of(null);
            }),
        )
    }

    private checkAuth( auth:AuthEsia ): boolean {
        console.log('Re:', auth);
        let err = null;
        console.log(' ->', auth);
        if( !auth || !auth.data ) err = { text: 'Данные для аутентификации не получены' }
        else if( auth.data.error ) err = auth.data.error;

        if( err ) this.app.noteAuthError(err);
        
        console.log(` -> ${auth.data.state}`);
        this.app.auth = auth;
        if( auth.data.state === 'auth' ){

            console.log(` -> ?`);
            this.app.noteAuthSuccess();
            return true;

        }else if( auth.data.state === 'wait' ){
            console.log(` -> org`);
            this.nav.navigateForward('/org');
        }else if( auth.data.state === 'unauth' && auth.data.url_follow ){
            this.app.auth = null;
            console.log(` -> ${auth.data.url_follow}`);
            this.app.authFollowUrl = auth.data.url_follow;
            this.nav.navigateRoot('/login');
        }else{
            console.log(` -> unauthorized`);
            this.app.auth = null;
            this.nav.navigateRoot('/login');
        }
        return false;
    }
}
