import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from '../app.service';

let counter = 0;
@Injectable({
    providedIn: 'root'
})
export class SelectOrgsGuard implements CanActivate {
    constructor(
        private app: AppService,
    ){}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {

        console.log('[SELECT-ORGS-GUARD]');

        if( this.app.auth && this.app.auth.data && this.app.auth.data.state === 'wait' ){
            return true;
        }else{
            this.app.gotoEntrance();
            return false;
        }
    }
}
